$(document).ready(function() {

    $(".dropdown-menu").hover(function() {
        $(this).prev().toggleClass( "arrow-visible" );
        //$(this).parent('dropdown').child('dropdown-toggle').toggleClass( "arrow-visible" );
    });

    $('.main-slide').slick({
        slidesToShow: 1,
        dots: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 10000,
        speed: 700
    });

    $('.news-slide').slick({
        slidesToShow: 4,
        slidesToScroll: 4,
        dots: true,
        arrows: false
    });

    $(".fancybox").fancybox();
    $("a[rel='lightbox']").fancybox();



    /*$('.dropdown-toggle, .dropdown-menu').hoverIntent(function () {
        $(this).not('.dropdown-menu').addClass( "arrow-visible" );
        $(this).next('.dropdown-menu').css('display', 'block');
    },function(){
        setTimeout(function() {
            $('.dropdown-menu').css('display', 'none');
            $('.dropdown-toggle').removeClass( "arrow-visible" );
        }, 500);
    });*/

});